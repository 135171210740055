import React, { useState, useEffect } from "react";

import Layout from "components/layout";
import CategoryOverview from "components/category_overview";
import { CategoryData } from "models/category";
import { adEngagePath, dataLoadingPath } from "utils/constants";
import { navigate } from "@reach/router";

import * as styles from "./styles.module.scss";
import axios from "axios";


const TopBrandsPublicAdEngage = () => {

  const [categoryData, setCategoryData] = useState<CategoryData>();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    axios.get(dataLoadingPath + "top_brands.json")
      .then((res: { data: CategoryData }) => {
        if (typeof res?.data != "object") {
          throw new Error("No data");
        }
        setCategoryData(res?.data);
      }).catch((err: Error) => {
      // redirect back to parent category if no data for brand
      navigate(adEngagePath + "/");
    })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Layout pageTitle={"Top Brands Overview"}>
      <CategoryOverview
        currentPath={""}
        title={"Top Brands"}
        subtitle={"Last 7 Days • Sorted By Airings"}
        content={categoryData?.metrics}
        contentLoading={loading}
        showRank />
    </Layout>
  )
};

export default TopBrandsPublicAdEngage;
